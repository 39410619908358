<template>
  <div>
    <FormCreateSelfPaid></FormCreateSelfPaid>
  </div>
</template>

<script>
import FormCreateSelfPaid from '@core/components/user-details-selfpaids/FormCreateSelfPaid.vue'

export default {
  components: {
    FormCreateSelfPaid,
  },
  data() {
    return {}
  },
}
</script>

<style>

</style>
